import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { LogLevel } from '@azure/msal-common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import {
  ApiConfiguration,
  AuthGuard,
  AuthInterceptor,
  AuthInterceptorConfig,
  CelumAuthModule,
  SERVICE_TOKEN_INTERCEPTOR_CONFIG,
  ServiceTokenInterceptorConfig,
  tenantHrefFactory,
  TenantService
} from '@celum/authentication';
import { CelumSnackbarModule } from '@celum/common-components';
import { CelumPropertiesProvider } from '@celum/core';
import { CelumSnackbarListModule } from '@celum/internal-components';
import { CustomTranslateLoader } from '@celum/ng2base';
import { AppErrorHandler } from '@celum/shared/util';

import { appRoutes } from './app.routes';
import { FormatsAppStoreModule } from './modules/formats-app-store.module';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(BrowserAnimationsModule),
    provideRouter(appRoutes),
    provideHttpClient(),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: translationFactory,
          deps: [HttpClient]
        }
      })
    ),
    importProvidersFrom(
      CelumAuthModule.forRoot({
        logLevel: LogLevel.Error
      })
    ),
    importProvidersFrom(CelumSnackbarModule, CelumSnackbarListModule),
    importProvidersFrom(FormatsAppStoreModule),
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    {
      provide: SERVICE_TOKEN_INTERCEPTOR_CONFIG,
      useFactory: serviceTokenInterceptorFactory,
      deps: [TenantService]
    },
    {
      provide: APP_BASE_HREF,
      deps: [TenantService],
      useFactory: tenantHrefFactory
    }
  ]
};

export function translationFactory(http: HttpClient): CustomTranslateLoader {
  return new CustomTranslateLoader(http);
}

function serviceTokenInterceptorFactory(tenantService: TenantService): ServiceTokenInterceptorConfig<AuthInterceptorConfig> {
  const getConfiguration = () => {
    const tenantIdForFormats = tenantService.getCurrentTenantId();

    const formatsAppServiceTokenRequest = {
      clientId: 'spor',
      orgId: tenantIdForFormats
    };

    const apiConfigurations: ApiConfiguration[] = [
      {
        apiUrls: [CelumPropertiesProvider.properties.appProperties.formats.apiUrl],
        serviceTokenRequestDto: formatsAppServiceTokenRequest,
        tenantIdHeader: tenantIdForFormats
      }
    ];

    return {
      apiConfigurations,
      passThrough403Error: true
    };
  };

  return {
    getInterceptorConfiguration: () => getConfiguration()
  };
}
