import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';

import { formatActions } from './format.actions';
import { FormatService } from '../../services/format.service';
import { NotificationService } from '../../services/notification.service';

@Injectable()
export class FormatEffects {
  private actions$ = inject(Actions);
  private formatService = inject(FormatService);
  private notificationService = inject(NotificationService);

  public loadFormats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formatActions.loadFormatsBatch),
      switchMap(() =>
        this.formatService.getFormats().pipe(
          map(result => formatActions.loadFormatsBatchSuccess({ formatsBatch: result.content, total: result.totalElements })),
          catchError(error => of(formatActions.loadFormatsBatchFailure({ error, message: 'FORMAT_LIST.SNACKBAR.LOAD_ERROR' })))
        )
      )
    )
  );

  public deleteFormat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formatActions.deleteFormat),
      mergeMap(action =>
        this.formatService.deleteFormat(action.formatId).pipe(
          map(() => formatActions.deleteFormatSuccess({ formatId: action.formatId })),
          catchError(error => of(formatActions.deleteFormatFailure({ error, message: 'FORMAT_LIST.SNACKBAR.DELETE_ERROR' })))
        )
      )
    )
  );

  public failing$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(formatActions.loadFormatsBatchFailure, formatActions.deleteFormatFailure),
        tap(({ message }) => this.notificationService.error(message))
      ),
    { dispatch: false }
  );

  public successDelete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(formatActions.deleteFormatSuccess),
        tap(() => this.notificationService.info('FORMAT_LIST.SNACKBAR.DELETE_SUCCESS'))
      ),
    { dispatch: false }
  );
}
